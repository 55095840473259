<template>
  <v-container>
    <h1>Clinic Locations</h1>
    <LocationsTable :locations="locations" :locationType="LocationTypes.CLINIC" />
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
import { LocationTypes } from '@/js/LocationTypes'
import LocationsTable from '@/components/Locations/LocationsTable'

export default {
  name: 'ClinicLocations',
  components: { LocationsTable },
  data: () => ({
    LocationTypes
  }),
  computed: {
    ...mapState({
      locations: state => state.Organization.locations
    })
  },
  mounted () {
    this.$store.dispatch('Organization/getLocations')
  }
}
</script>
